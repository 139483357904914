import styled from "styled-components";

export const MapWrapper = styled.div`
  padding: 0;
  margin: 0;
  height: 450px;
  width: 100%;
  background-color: #f4f4f6;
  color: #fafafa;
`;
