import { useScrollTrigger, Zoom } from "@material-ui/core";
import { FabWrapper } from "./index.style";

interface Props {
  children: React.ReactElement;
}

const ScrollTop = (props: Props) => {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 400,
  });

  return (
    <Zoom in={trigger}>
      <FabWrapper
        to="heroSection"
        spy={true}
        smooth={true}
        offset={-70}
        duration={500}
      >
        {children}
      </FabWrapper>
    </Zoom>
  );
};

export default ScrollTop;
