import styled from "styled-components";

export const ContactWrapper = styled.div`
  padding: 120px 0 130px 0;
  height: auto;
  width: 100%;
  background-color: #50a8b4;
  color: #fafafa;
`;

export const LogoImage = styled.img`
  max-height: 150px;
  width: 100%;
`;
