import styled from "styled-components";

export const SectionWrapper = styled.div`
  padding: 100px 0 140px 0;
  height: auto;
  width: 100%;
  background-color: #f5fbfc;
`;

export const TitleBorder = styled.div`
  background-color: #50a8b4;
  width: 55px;
  height: 2px;
  margin-right: auto;
  margin-left: auto;
`;
