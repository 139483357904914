import { Box, Container, Grid, Typography } from "@material-ui/core";
import { ReserveWrapper, SectionImage, TitleBorder } from "./index.style";
import ReservationImage from "../../../../images/section/Telemedicine_SVG.svg";
import { PrimaryLinkButton } from "../../../../shared/PrimaryLinkButton/index.style";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import DescriptionIcon from "@material-ui/icons/Description";

const Reservation = () => {
  return (
    <ReserveWrapper id="reservationSection">
      <Container maxWidth="lg" component="main">
        <Grid container spacing={3} justify="center" alignItems="center">
          <Grid item xs={12} md={6}>
            <SectionImage src={ReservationImage} alt="reservation image" />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              component="h1"
              variant="h4"
              align="left"
              color="textPrimary"
            >
              Reservasi
            </Typography>
            <Box my={3}>
              <TitleBorder />
            </Box>
            <Box mt={5} mb={2}>
              <Typography
                variant="body1"
                align="left"
                color="textSecondary"
                component="p"
              >
                Jangan lupa periksa gigi anda setiap 6 bulan sekali.
                <br />
                Konsultasi dan reservasi akan segera kami layani saat jam kerja:
                <br />
                Senin-Minggu, pukul 09.00-20.00
              </Typography>
            </Box>
            <Box my={3} display="flex">
              <PrimaryLinkButton
                target="_blank"
                href="https://wa.me/message/EW3VBPV4KMCHP1"
              >
                <Box display="inline-flex" mr={1}>
                  <WhatsAppIcon fontSize="small" />
                </Box>
                <Typography variant="button">Whatsapp</Typography>
              </PrimaryLinkButton>
              <PrimaryLinkButton
                outlined
                target="_blank"
                href="https://forms.gle/tmGXZzTcadnrBidM8"
              >
                <Box display="inline-flex" mr={1}>
                  <DescriptionIcon fontSize="small" />
                </Box>
                <Typography variant="button">Isi Form</Typography>
              </PrimaryLinkButton>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </ReserveWrapper>
  );
};

export default Reservation;
