import {
  Container,
  Hidden,
  IconButton,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { useScrollTrigger } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { menuData } from "../../data/MenuData";
import Sidebar from "../Sidebar";
import {
  Logo,
  LogoImage,
  LogoWrapper,
  Nav,
  NavMenu,
  NavMenuLink,
  Spacer,
} from "./index.style";
import LogoHorizontal from "../../images/logo_horizontal.svg";
import LogoHorizontalLight from "../../images/logo_horizontal_light.svg";

interface Props {
  isOpen: boolean;
  toggle: () => void;
}

const NavBar: React.FC<Props> = ({ toggle, isOpen }) => {
  const trigger = useScrollTrigger({ disableHysteresis: true });

  return (
    <>
      <Nav position="fixed" elevation={0} trigger={trigger ? 1 : 0}>
        <Container maxWidth="lg">
          <Toolbar disableGutters id="back-to-top-anchor">
            <LogoWrapper>
              <Logo to="/" aria-label="Home">
                {trigger ? (
                  <LogoImage src={LogoHorizontal} alt="Ethes Dental Care" />
                ) : (
                  <LogoImage
                    src={LogoHorizontalLight}
                    alt="Ethes Dental Care"
                  />
                )}
                {/* <Typography variant="h6">Ethes Dental Care</Typography> */}
              </Logo>
            </LogoWrapper>
            <Spacer />
            <Hidden smDown>
              <NavMenu>
                {menuData.map((item, index) => (
                  <NavMenuLink
                    key={index}
                    to={item.link}
                    spy={true}
                    smooth={true}
                    offset={-62}
                    duration={500}
                  >
                    <Typography variant="button">{item.title}</Typography>
                  </NavMenuLink>
                ))}
              </NavMenu>
            </Hidden>
            <Hidden mdUp>
              <IconButton color="inherit" aria-label="menu" onClick={toggle}>
                <MenuIcon />
              </IconButton>
            </Hidden>
          </Toolbar>
        </Container>
      </Nav>
      <Hidden mdUp>
        <Sidebar isOpen={isOpen} toggle={toggle} />
      </Hidden>
    </>
  );
};

export default NavBar;
