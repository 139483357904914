import { List } from "@material-ui/core";
import { Link as LinkS } from "react-scroll";
import styled from "styled-components";

interface SidebarProps {
  isOpen: boolean;
}

export const SidebarWrapper = styled.aside<SidebarProps>`
  position: fixed;
  z-index: 1200;
  width: 100%;
  height: 100%;
  background: #ffffff;
  color: #50a8b4;
  display: grid;
  align-items: center;
  top: 0;
  left: 0;
  transition: 0.3s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? "100%" : "0")};
  top: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
`;

export const Nav = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
`;

export const MenuWrapper = styled(List)``;

export const NavMenu = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(6, 80px);
  text-align: center;

  @media screen and (max-width: 480px) {
    grid-template-rows: repeat(6, 60px);
  }
`;

export const NavMenuLink = styled(LinkS)`
  text-align: center;
  width: 100%;
  padding: 20px 0;
`;
