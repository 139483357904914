import styled, { css } from "styled-components";
import { Link } from "react-scroll";

const Button = css`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  color: #fafafa;
  transition: all 0.3s;
  font-size: 14px;
  padding: 10px 22px;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  line-height: 1.5;
  border-radius: 0.35rem;
  border: 0px;
  text-decoration: none;
`;

const Outlined = css`
  border: 1px solid #50a8b4;
  color: #50a8b4;
  background-color: #fafafa;
  box-shadow: rgb(80 168 180 / 28%) 0px 4px 12px;

  &:hover {
    background-color: #efefef;
    transform: translateY(-5px);
  }
`;

interface Props {
  outlined?: boolean;
}

export const PrimaryButton = styled(Link)<Props>`
  ${Button}
  background-color: #50a8b4;
  box-shadow: rgb(80 168 180 / 48%) 0px 4px 12px;

  &:hover {
    background-color: #3c909c;
    transform: translateY(-5px);
  }

  ${(p) => p.outlined && Outlined}
`;
