import Feature from "./components/Feature";
import About from "./components/About";
import Contact from "./components/Contact";
import Reservation from "./components/Reservation";
import Testimony from "./components/Testimony";
import Header from "./components/Header";
import MapSection from "./components/Map";
import JadwalPraktik from "./components/JadwalPraktik";
import { TestimonyData } from "../../data/TestimonyData";
import { featureData } from "data/FeatureData";
import { scheduleData } from "data/ScheduleData";

const Home = () => {
  return (
    <>
      {/* <Hero slides={SliderData} /> */}
      <Header />
      <Feature features={featureData} />
      <About />
      <JadwalPraktik schedules={scheduleData} />
      <Reservation />
      <Testimony testimony={TestimonyData} />
      <MapSection />
      <Contact />
    </>
  );
};

export default Home;
