import styled from "styled-components";

export const ReserveWrapper = styled.div`
  padding: 100px 0 200px 0;
  height: auto;
  width: 100%;
  background-color: #ffffff;
`;

export const TitleBorder = styled.div`
  background-color: #50a8b4;
  width: 55px;
  height: 2px;
`;

export const SectionImage = styled.img`
  width: 100%;
  padding-left: 80px;
`;

export const Frame = styled.iframe`
  border: none;
  height: 500px;
`;
