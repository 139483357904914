import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import styled from "styled-components";
import { AboutWrapper, TitleBorder } from "./index.style";

const FeatureCard = styled(Card)`
  border-radius: 0.55rem;
  box-shadow: rgb(80 168 180 / 28%) 0px 4px 12px;
  /* display: flex;
  justify-content: center; */
  transition: all ease 0.2s;
  position: relative;
  top: 0px;
  &:hover {
    top: -10px;
    box-shadow: rgb(80 168 180 / 30%) 0px 14px 22px;
  }
`;

interface MyProps {
  schedules: Schedule[];
}

interface Schedule {
  name: string;
  schedule: ScheduleTime[];
  image: string;
}

interface ScheduleTime {
  day: string;
  time: string;
}

const Section: React.FC<MyProps> = ({ schedules }) => {
  return (
    <AboutWrapper id="practiceSection">
      <Container maxWidth="md" component="main">
        <Typography
          component="h1"
          variant="h4"
          align="center"
          color="textPrimary"
        >
          JADWAL PRAKTIK
        </Typography>
        <Box my={3}>
          <TitleBorder />
        </Box>
        <Box my={5}>
          <Typography
            variant="body1"
            align="center"
            color="textSecondary"
            component="p"
          >
            Jadwal praktik dokter gigi Ethes Dental Care.
          </Typography>
        </Box>
        <Box>
          <Grid container spacing={5} alignItems="flex-end">
            {schedules.map((schedule, index) => (
              // Enterprise card is full width at sm breakpoint
              <Grid item key={index} xs={12} sm={6} md={4}>
                <FeatureCard>
                  <CardMedia
                    image={schedule.image}
                    title={schedule.name}
                    style={{ height: "250px" }}
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="h2"
                      align="left"
                    >
                      {schedule.name}
                    </Typography>
                    {/* <TitleBorder style={{ marginLeft: "0" }} /> */}
                    <List dense>
                      {schedule.schedule.map((schedule, index) => (
                        <ListItem disableGutters key={index}>
                          <ListItemIcon>
                            <Typography color="textPrimary" variant="body2">
                              {schedule.day}
                            </Typography>
                          </ListItemIcon>
                          <ListItemText
                            style={{ margin: "0" }}
                            primary={
                              <Typography color="textSecondary" variant="body2">
                                {schedule.time}
                              </Typography>
                            }
                          />
                        </ListItem>
                      ))}
                    </List>
                    <TitleBorder style={{ marginTop: "8px" }} />
                  </CardContent>
                </FeatureCard>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </AboutWrapper>
  );
};

export default Section;
