export const menuData = [
  {
    title: "Layanan",
    link: "offerSection",
  },
  {
    title: "Tentang",
    link: "aboutSection",
  },
  {
    title: "Reservasi",
    link: "reservationSection",
  },
  {
    title: "Testimoni",
    link: "testimonySection",
  },
];
