import { Container } from "@material-ui/core";
import styled from "styled-components";

interface Props {
  img: string;
}

export const HeaderWrapper = styled.div`
  height: 85vh;
  width: 100%;
  background: #50a8b4;
`;

export const HeaderImage = styled.div<Props>`
  /* Use "linear-gradient" to add a darken background effect to the image (photographer.jpg). This will make the text easier to read */
  background-image: linear-gradient(#3f51b52e, #00bcd400),
    url("${(p) => p.img}");

  /* Set a specific height */
  height: 100%;

  /* Position and center the image to scale nicely on all screens */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
`;

export const HeaderContainer = styled(Container)`
  height: 100%;
`;

export const HeaderText = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  color: #ffffff;
`;
