import AgatyaPhoto from "images/profile/agatya.jpeg";
import GumilangPhoto from "images/profile/gumilang.jpeg";
import SiwiPhoto from "images/profile/siwi.jpeg";

export const scheduleData = [
  {
    name: "Drg. Gumilang Adhi P",
    image: GumilangPhoto,
    schedule: [
      { day: "Kamis", time: "14.30 - 20.00" },
      { day: "Jumat", time: "14.30 - 20.00" },
      { day: "Sabtu", time: "14.30 - 20.00" },
    ],
  },
  {
    name: "Drg. Agatya Rahmawati P",
    image: AgatyaPhoto,
    schedule: [
      { day: "Senin", time: "09.00 - 20.00" },
      { day: "Selasa", time: "09.00 - 20.00" },
      { day: "Rabu", time: "09.00 - 20.00" },
    ],
  },
  {
    name: "Drg. Siwi Purwaningrum",
    image: SiwiPhoto,
    schedule: [
      { day: "Kamis", time: "09.00 - 14.30" },
      { day: "Jumat", time: "09.00 - 14.30" },
      { day: "Sabtu", time: "09.00 - 14.30" },
    ],
  },
];
