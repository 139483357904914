import { Fab } from "@material-ui/core";
import styled from "styled-components";

export const Wrapper = styled.div``;

export const SrollTopButton = styled(Fab)`
  background-color: #50a8b4;
  color: #fafafa;
  box-shadow: rgb(21 92 101 / 50%) 0px 4px 12px;

  &:hover {
    background-color: #3c909c;
  }
`;
