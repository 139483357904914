import React, { useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import NavBar from "../NavBar";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import ScrollTop from "../ScrollTop";
import Home from "../../pages/Home";
import Footer from "../Footer";
import { SrollTopButton } from "./index.style";

interface Props {
  children?: React.ReactElement;
}

export const MainLayout = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <NavBar isOpen={isOpen} toggle={toggle} />
      <main>
        <Home />
      </main>
      {/* Footer */}
      <Footer />
      {/* End footer */}
      <ScrollTop {...props}>
        <SrollTopButton aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </SrollTopButton>
      </ScrollTop>
    </React.Fragment>
  );
};
