export const TestimonyData = [
  {
    contrib: "Katrin Ayunin",
    comment:
      "Pelayananya sangat memuaskan. Dokternya cantik & humble and gaul. Tempatnya juga bersih, nyaman & aman. Recomended banget nihh buat perawatan gigi.",
  },
  {
    contrib: "Artika BP",
    comment:
      "Anak saya tambal gigi di sini, gak nangis.. Dokternya ramaah sekali.. Adminnya juga ramah.. Profesional tentunya.",
  },
  {
    contrib: "Honey Zahara",
    comment:
      "Puas banget sama pelayanan di ethes dental care. Dokternya ramah bgt. Pokoknya top deh buat ethes dental care.",
  },
  {
    contrib: "Dapur Mama",
    comment: "Nyaman bgt.. Bersih dan ramah dokter2nya.",
  },
  {
    contrib: "Siwi Purwaningrum",
    comment:
      "Modern banget tempatnya, nyaman dan milenial banget. Gak nyesel perawatan gigi disni.",
  },
  {
    contrib: "Arip Soreawan",
    comment:
      "Tempatnya periksa Gigi yg nyaman, Ruang tunggu juga ok. Dokter- dokternya Friendly.",
  },
  {
    contrib: "Nunung WP",
    comment:
      "Scaling pertama dengan dokter Agatya yang super cantik dan ramaaah pastinya sabar dong.",
  },
];
