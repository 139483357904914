import styled, { css } from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { Link as LinkS } from "react-scroll";
import { AppBar } from "@material-ui/core";

interface NavProps {
  readonly trigger: number;
}

export const Nav = styled(AppBar)<NavProps>`
  background: ${(props) => (props.trigger ? "#fff" : "transparent")};
  color: ${(props) => (props.trigger ? "#50a8b4" : "#fff")};
  transition: 0.5s all ease;
`;

export const LogoWrapper = styled.div`
  color: inherit;
  text-decoration: none;
`;

export const Logo = styled(LinkR)`
  color: inherit;
  text-decoration: none;
`;

export const LogoImage = styled.img`
  height: 42px;
  padding-top: 4px;
`;

export const Spacer = styled.div`
  flex-grow: 1;
`;

export const NavMenu = styled.div``;

const NavLink = css`
  color: inherit;
  padding: 0px 24px;
  text-decoration: none;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    color: #3c909c;
  }
`;

export const NavMenuLink = styled(LinkS)`
  ${NavLink}
`;

export const NavButton = styled.div``;
