import { Meta } from "./Meta";
import { MainLayout } from "./shared/MainLayout";

const App = () => {
  return (
    <div className="App">
      <Meta />
      <MainLayout />
    </div>
  );
};

export default App;
