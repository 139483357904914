import { Box, Container, Grid, Paper, Typography } from "@material-ui/core";
import { SectionWrapper, TitleBorder } from "./index.style";
import styled from "styled-components";
import React from "react";

const IconImage = styled.img`
  height: 120px;
`;

const FeaturePaper = styled(Paper)`
  padding: 8px 12px;
  height: 200px;
  border-radius: 0.55rem;
  box-shadow: rgb(80 168 180 / 28%) 0px 4px 12px;
  /* display: flex;
  justify-content: center; */
  transition: all ease 0.2s;
  position: relative;
  top: 0px;
  &:hover {
    top: -10px;
    box-shadow: rgb(80 168 180 / 30%) 0px 14px 22px;
  }
`;

const FeatureContent = styled(Box)`
  height: 64px;
`;

interface FeatureProps {
  features: Feature[];
}

interface Feature {
  title: string;
  image: string;
}

const SectionPage: React.FC<FeatureProps> = ({ features }) => {
  return (
    <SectionWrapper id="offerSection">
      <Container maxWidth="md" component="main">
        <Typography
          component="h1"
          variant="h4"
          align="center"
          color="textPrimary"
        >
          LAYANAN KAMI
        </Typography>
        <Box my={3}>
          <TitleBorder />
        </Box>
        <Box my={5}>
          <Grid container spacing={4}>
            {features.map((feature, index) => {
              return (
                <Grid item xs={12} sm={4} md={3} key={index}>
                  <FeaturePaper>
                    <Box component="div" display="flex" justifyContent="center">
                      <IconImage src={feature.image} />
                    </Box>
                    <FeatureContent
                      component="div"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography align="center" variant="body1">
                        <b>{feature.title}</b>
                      </Typography>
                    </FeatureContent>
                  </FeaturePaper>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Container>
    </SectionWrapper>
  );
};

export default SectionPage;
