import { Box, Grid, Hidden, Typography } from "@material-ui/core";
import HeroImage from "../../../../images/hero1.jpg";
import { PrimaryButton } from "../../../../shared/PrimaryButton/index.style";
import {
  HeaderContainer,
  HeaderImage,
  HeaderText,
  HeaderWrapper,
} from "./index.style";

const Header = () => {
  return (
    <HeaderWrapper id="heroSection">
      <HeaderImage img={HeroImage}>
        <HeaderContainer maxWidth="lg">
          <HeaderText>
            <Grid container alignContent="center">
              <Grid item xs={12} md={6}>
                <Hidden smDown>
                  <Typography variant="h2" component="h1">
                    Ethes Dental Care
                  </Typography>
                  <Box mb={1}>
                    <Typography variant="h6" component="p">
                      Mulai perawatan gigi dan mulut Anda dengan reservasi
                      sekarang!
                    </Typography>
                  </Box>
                </Hidden>
                <Hidden mdUp>
                  <Typography variant="h3" component="h1">
                    Ethes Dental Care
                  </Typography>
                  <Box mb={1}>
                    <Typography variant="body1" component="p">
                      Mulai perawatan gigi dan mulut Anda dengan reservasi
                      sekarang!
                    </Typography>
                  </Box>
                </Hidden>

                <PrimaryButton
                  to="reservationSection"
                  outlined
                  spy={true}
                  smooth={true}
                  offset={-62}
                  duration={500}
                >
                  <Typography variant="button">Reservasi</Typography>
                </PrimaryButton>
              </Grid>
            </Grid>
          </HeaderText>
        </HeaderContainer>
      </HeaderImage>
    </HeaderWrapper>
  );
};

export default Header;
