import {
  Indicator,
  TestimonyWrapper,
  TestimonyItem,
  TestimonyCarousel,
} from "./index.style";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Box, Container, Typography } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import FormatQuoteRoundedIcon from "@material-ui/icons/FormatQuoteRounded";

interface ITestimony {
  contrib: string;
  comment: string;
}

interface Props {
  testimony: ITestimony[];
}

const Testimony: React.FC<Props> = ({ testimony }) => {
  return (
    <TestimonyWrapper id="testimonySection">
      <Container maxWidth="md">
        <TestimonyCarousel
          autoPlay
          interval={5000}
          infiniteLoop
          showArrows={false}
          showStatus={false}
          showThumbs={false}
          renderIndicator={(onClickHandler, isSelected, index, label) => {
            if (isSelected) {
              return <Indicator isactive />;
            }
            return (
              <Indicator onClick={onClickHandler} onKeyDown={onClickHandler} />
            );
          }}
        >
          {testimony.map((data, i) => {
            return (
              <TestimonyItem key={i}>
                <Box mb={3}>
                  <FormatQuoteRoundedIcon style={{ fontSize: 52 }} />
                </Box>
                <Box my={2}>
                  <Typography
                    variant="body1"
                    align="center"
                    color="textSecondary"
                    component="p"
                  >
                    {data.comment}
                  </Typography>
                  <Rating value={5} readOnly />
                </Box>
                <Box>
                  <Typography variant="body1">
                    <b>{data.contrib}</b>
                  </Typography>
                </Box>
              </TestimonyItem>
            );
          })}
        </TestimonyCarousel>
      </Container>
    </TestimonyWrapper>
  );
};

export default Testimony;
