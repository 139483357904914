import { MapWrapper } from "./index.style";

const MapSection = () => {
  return (
    <MapWrapper id="mapSection">
      <iframe
        title="google-map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3953.920618106644!2d110.61382541494874!3d-7.691668494455249!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e7a43d63935e46b%3A0x701b54166ba75514!2sEthes%20Dental%20Care!5e0!3m2!1sen!2sid!4v1619481775243!5m2!1sen!2sid"
        width="100%"
        height="450"
        style={{ border: 0, lineHeight: 0 }}
        loading="lazy"
      ></iframe>
    </MapWrapper>
  );
};

export default MapSection;
