import {
  Container,
  IconButton,
  List,
  ListItem,
  Toolbar,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { menuData } from "../../data/MenuData";
import { MenuWrapper, Nav, NavMenuLink, SidebarWrapper } from "./index.style";

interface Props {
  isOpen: boolean;
  toggle: () => void;
}

const Sidebar: React.FC<Props> = ({ isOpen, toggle }) => {
  return (
    <SidebarWrapper isOpen={isOpen}>
      <Nav>
        <Container maxWidth="lg">
          <Toolbar disableGutters>
            <IconButton color="inherit" aria-label="menu" onClick={toggle}>
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </Container>
      </Nav>
      <MenuWrapper>
        <List component="nav" aria-label="secondary mailbox folders">
          {menuData.map((item, index) => (
            <ListItem button key={index}>
              <NavMenuLink
                to={item.link}
                spy={true}
                smooth={true}
                offset={-54}
                duration={500}
                onClick={toggle}
              >
                <Typography align="center" variant="button">
                  {item.title}
                </Typography>
              </NavMenuLink>
            </ListItem>
          ))}
        </List>
      </MenuWrapper>
    </SidebarWrapper>
  );
};

export default Sidebar;
