import styled, { css } from "styled-components";
import { Carousel } from "react-responsive-carousel";

export const TestimonyWrapper = styled.div`
  padding: 100px 0 180px 0;
  height: auto;
  width: 100%;
  background-color: #f5fbfc;
`;

export const TestimonyCarousel = styled(Carousel)`
  .carousel-slider {
    height: 100%;
    overflow: visible;
  }

  .control-dots {
    bottom: -60px;
    display: flex;
    justify-content: center;
  }
`;

export const TestimonyItem = styled.div`
  height: 100%;
  display: block;
`;

interface Props {
  isactive?: boolean;
}

export const Indicator = styled.li<Props>`
  display: inline-block;
  background: #cacaca;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  border: 0;
  margin: 5px;
  cursor: pointer;

  ${(p) => p.isactive && ActiveIndicator}
`;

const ActiveIndicator = css`
  background: #50a8b4;
`;
