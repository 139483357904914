import { Box, Container, Typography } from "@material-ui/core";
import { PrimaryButton } from "../../../../shared/PrimaryButton/index.style";
import { AboutWrapper, TitleBorder } from "./index.style";

const About = () => {
  return (
    <AboutWrapper id="aboutSection">
      <Container maxWidth="md" component="main">
        <Typography
          component="h1"
          variant="h4"
          align="center"
          color="textPrimary"
        >
          TENTANG KAMI
        </Typography>
        <Box my={3}>
          <TitleBorder />
        </Box>
        <Box my={5}>
          <Typography
            variant="body1"
            align="center"
            color="textSecondary"
            component="p"
          >
            Ethes Dental Care merupakan tempat praktek dokter gigi. Berbagai
            perawatan kesehatan gigi dan mulut bisa dilakukan disini mulai dari
            treatment estetik, preventif, sampai kuratif. Protokol kesehatan
            selalu kami patuhi. Setiap pergantian pasien selalu kami UV dan
            disinfektan untuk membunuh virus dan bakteri. Ke dokter gigi saat
            pandemi? Siapa takut.
          </Typography>
        </Box>
        <Box my={2} display="flex" justifyContent="center">
          <PrimaryButton
            to="testimonySection"
            spy={true}
            smooth={true}
            offset={-62}
            duration={500}
          >
            <Typography variant="button">Testimoni</Typography>
          </PrimaryButton>
        </Box>
      </Container>
    </AboutWrapper>
  );
};

export default About;
