import { Container, Link, Typography } from "@material-ui/core";
import { FooterWrapper } from "./index.style";

function Copyright() {
  return (
    <Typography variant="body2" align="center">
      {"© "}
      {new Date().getFullYear()}{" "}
      <Link color="inherit" href="/" aria-label="Home">
        Ethes Dental Care
      </Link>{" "}
    </Typography>
  );
}

const Footer = () => {
  return (
    <FooterWrapper>
      <Container maxWidth="lg">
        <Copyright />
      </Container>
    </FooterWrapper>
  );
};

export default Footer;
