import BleechingIcon from "images/icon/bleeching.svg";
import BraceIcon from "images/icon/braces.svg";
import BridgeIcon from "images/icon/bridge.svg";
import CabutIcon from "images/icon/cabut-gigi.svg";
import CrownIcon from "images/icon/crown.svg";
import DiamondIcon from "images/icon/diamond.svg";
import GigiPalsuIcon from "images/icon/gigi-palsu.svg";
import PeriksaIcon from "images/icon/periksa-gigi.svg";
import AkarGigiIcon from "images/icon/rawat-akar.svg";
import ScalingIcon from "images/icon/scaling.svg";
import TambalIcon from "images/icon/tambal-gigi.svg";
import VeneerIcon from "images/icon/veneer.svg";

export const featureData = [
  {
    title: "Pemeriksaan Gigi Rutin",
    image: PeriksaIcon,
  },
  {
    title: "Penambalan Gigi Depan & Belakang",
    image: TambalIcon,
  },
  {
    title: "Pencabutan Gigi Dewasa & Anak",
    image: CabutIcon,
  },
  {
    title: "Perawatan Saluran Akar",
    image: AkarGigiIcon,
  },
  {
    title: "Braces & Retainer",
    image: BraceIcon,
  },
  {
    title: "Pembersihan Karang Gigi",
    image: ScalingIcon,
  },
  {
    title: "Jaket Gigi",
    image: CrownIcon,
  },
  {
    title: "Veneer",
    image: VeneerIcon,
  },
  {
    title: "Bleaching",
    image: BleechingIcon,
  },
  {
    title: "Diamond Gigi",
    image: DiamondIcon,
  },
  {
    title: "Gigi Palsu Jembatan",
    image: BridgeIcon,
  },
  {
    title: "Gigi Palsu Lepas Pasang",
    image: GigiPalsuIcon,
  },
];
