import { Box, Container, Grid, Link, Typography } from "@material-ui/core";
import { ContactWrapper } from "./index.style";
import InstagramIcon from "@material-ui/icons/Instagram";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

const Contact = () => {
  return (
    <ContactWrapper id="contactSection">
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          spacing={4}
        >
          <Grid item xs={12} md={12}>
            <Box mb={1}>
              <Typography variant="h5" component="h1" align="center">
                Ethes Dental Care
              </Typography>
            </Box>
            <Typography variant="body2" component="p" align="center">
              Jl. Diponegoro, Jetak Kidul, Karanganom, Kec. Klaten Utara,
              Kabupaten Klaten, Jawa Tengah 57438
            </Typography>
            <Box my={1}>
              <Typography variant="body2" component="p" align="center">
                0813-9281-9911
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center">
              <Box component="div" mr={1}>
                <Link
                  href="https://www.instagram.com/ethes.dentalcare/"
                  target="_blank"
                  color="inherit"
                >
                  <InstagramIcon />
                </Link>
              </Box>
              <Box component="div">
                <Link
                  href="https://wa.me/message/EW3VBPV4KMCHP1"
                  target="_blank"
                  color="inherit"
                >
                  <WhatsAppIcon />
                </Link>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </ContactWrapper>
  );
};

export default Contact;
