import { Helmet } from "react-helmet";

export const Meta = () => {
  return (
    <Helmet>
      {/* -- Primary Meta Tags -- */}
      <title>Ethes Dental Care</title>
      <meta name="title" content="Ethes Dental Care" />
      <meta
        name="description"
        content="Ethes Dental Care merupakan tempat prakter dokter gigi. Berbagai perawatan kesehatan gigi dan mulut bisa dilakukan disini."
      />
      <meta
        name="keywords"
        content="dental care klaten, dental, clinic, dokter gigi, gigi, mulut, klaten, perawatan"
      />
      <meta name="robots" content="index, follow" />

      {/* -- Open Graph / Facebook -- */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://ethesdentalcare.id/" />
      <meta property="og:title" content="Ethes Dental Care" />
      <meta
        property="og:description"
        content="Ethes Dental Care merupakan tempat prakter dokter gigi. Berbagai perawatan kesehatan gigi dan mulut bisa dilakukan disini."
      />
      <meta property="og:image" content="/meta-image.jpg" />

      {/* -- Twitter -- */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content="https://ethesdentalcare.id/" />
      <meta property="twitter:title" content="Ethes Dental Care" />
      <meta
        property="twitter:description"
        content="Ethes Dental Care merupakan tempat prakter dokter gigi. Berbagai perawatan kesehatan gigi dan mulut bisa dilakukan disini."
      />
      <meta property="twitter:image" content="/meta-image.jpg" />
    </Helmet>
  );
};
